<template>
  <transition name="modal">
    <div v-if="!showBicoinFaq" class="modal-mask" style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="disinter-main">
          <div class="disinter-inner">
            <p class="header">Konuşmayı Başlat</p>
            <p class="paragraph1">
              Bu kullanıcı ile aranızda daha önce bir konuşma bulunamadı. Potansiyel alıcılarla bir konuşma başlatmak için bicoin'lerini kullanabilirsin.
              <a @click="activateBicoinFAQModal()" class="paragraph-more">
                Daha fazla bilgi
              </a>
            </p>
            <br>
            <div style="display: flex;flex-direction: column;align-items: flex-start">

              <div>
                <span>Mevcut bicoin:</span>
                <span style="font-weight: bold"> {{ totalBicoin }} bicoin </span>
              </div>
            </div>
          </div>
          <div class="disinter-buttons-div">
            <a @click="backButton()" style="margin-top: 10px;  color: #5e6b79; margin-right: 10px; font-size: 14px" >Geri</a>
            <button @click="accept($event)" class="okey-button" style=" font-size: 14px; margin-left: 10px; padding: 2px 0 4px 0">Başlat (20 bicoin)</button>
          </div>

        </div>
      </div>
    </div>
    <div class="modal-mask" style="overflow-y: scroll" v-if="showBicoinFaq">
      <div class="modal-container" style="overflow: scroll;max-height: 100%" @click.stop>
        <div style="float: right; padding: 10px;">
          <img @click="showBicoinFaq = false" style=" cursor:pointer;width: 20px; height: 20px;" :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>
        <div style="padding: 30px">
          <div class="hiddendiv"></div>
          <h2 style="font-size: 32px;font-weight: 600;color: #2d3640;text-align: center;">
            bicoin nedir?
          </h2>
          <div class="hiddendiv"></div>
          <div class="hiddendiv"></div>
          <p style="color:#2d3640; font-size: 17px; line-height: 1.3;">
            bicoin; bionluk.com üyesi freelancer’ların Bionluk içerisinde gerçekleştirdiği satışlar ile site
            içerisinde kullanılabilen bakiye kazandıkları özel bir kullanıcı programıdır.
          </p>
          <div class="hiddendiv"></div>
          <div>
            <h3 style="font-size: 24px;font-weight: 500;color: #2d3640;padding-bottom: 10px">
              Sık Sorulan Sorular
            </h3>
            <div class="faq-list">
              <div v-for="item in faqList" class="faq-list-item" @click="changeActiveStatus(item)">
                <span class="faq-list-item-question">{{ item.question }}</span>
                <transition name="fade">
                  <p v-if="item.isActive" class="faq-list-item-answer">
                    <span v-html="item.answer"></span>
                  <ol style="margin-top: 10px; margin-left: 25px;">
                    <li v-for="listItem in item.listItems" style="list-style-type: disc">
                      {{ listItem }}
                    </li>
                  </ol></p>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

  export default {
    name: "src-pages-modals-unlockChat-v1_mobile",
    data() {
      return {
        totalBicoin: 0,
        showBicoinFaq: false,
        chatID: null,
        comingFrom: null,
        disintermediation: false,
        faqList: [
          {
            isActive: false,
            question: "bicoin parayla satılır mı?",
            answer: "Hayır. bicoin sadece Bionluk'taki satış performansına bağlı olarak hesabına yüklenir. Parayla satılmaz," +
            " transfer edilemez."
          },
          {
            isActive: false,
            question: "Nasıl bicoin kazanırım?",
            answer: "1 TL tutarında yapmış olduğun satış onayladığı takdirde 1 bicoin kazanırsın. Örneğin " +
            "100TL tuturında bir satış yaptın ve teslim ettin. Alıcı onaylandığı anda 100 bicoin hesabına " +
            "otomatik olarak yüklenir. Kazandığın bicoin'leri yükleme tarihinden itibaren bir yıl içinde " +
            "kullanmalısın."
          },
          {
            isActive: false,
            question: "bicoin'lerini nasıl harcarsın?",
            answer: "bicoin'lerinle bionluk.com içerisinde, satışlarını artıracak birçok farklı özellikten " +
            "yararlanabilirsin.",
            listItems: [
              "Benim İşim Acil! bölümünde ekstra dakika satın alabilirsin",
              "Kategori sayfalarında ilanını öne çıkanlar bölümünde sergiyebilirsin",
              "Henüz aranızda hiç konuşma bulunmayan bir alıcıya mesaj atmak isterken bicoin’lerini kullanabilirsin."
            ]
          },
          {
            isActive: false,
            question: "BİA’da bicoin işlemleri nasıl yapılır?",
            answer: "600 bicoin ile 1 sene boyunca kullanabileceğin 60 dakika satın alabilirsin. Almış olduğun " +
            "dakikalar 1 sene sonra sıfırlanır ve kalan bicoin’ler iade edilemez. Bu alanda kullanmış olduğun " +
            "bicoin’lerini farklı bir özellik almak için geri dönüştüremezsin."
          },
          {
            isActive: false,
            question: "Kategori listeleme sayfalarında öne çıkma özelliği nasıl çalışır?",
            answer: "500 bicoin ile 1 adet ilanının listeleme sayfalarında en üst sırada çıkmasını sağlayabilirsin. " +
            "Aynı anda gösterilen maksimum ilan sayısı 3'tür. Bu alandaki ilan sıralamaları gün içinde değişmekte ve ilanın 24 " +
            "saat süre ile sabit olarak öne çıkan ilanlar arasında sergilenmektedir." +
            "<br/>" +
            "Bu alanda kullanmış olduğun bicoin'lerini farklı bir özellik almak için geri dönüştüremezsin."
          },
          {
            isActive: false,
            question: "Alıcılarla mesajlaşırken uygulanan bicoin kuralları nelerdir? ",
            answer: "Daha önce aranızda mesaj geçmişi olan alıcılarla dilediğin kadar mesajlaşmaya devam " +
            "edebilirsin. Aranızda henüz hiç konuşma bulunmayan bir alıcıya mesaj atmak istersen 20 " +
            "bicon kullanman gerekiyor."
          },
        ]
      }
    },
    methods: {
      accept(event) {
        event.preventDefault();
        event.target.disabled = true;
        this.api.chat.unlockChat(this.chatID)
          .then(({data}) => {
            let result = data;

            if (result.success) {
	            this.user.info.total_bicoin = this.totalBicoin - 20;
              if (this.disintermediation) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.DISINTERMEDIATION, info: {comingFrom: this.comingFrom}});
              }
            } else {
              this.$toasted.error(result.message);
              this.routeBack("/", false);
            }
          })
          .catch(err => {
            this.$toasted.error(err);
          })

        this.hide();
      },

      changeActiveStatus(item) {
        this.faqList.forEach(value => {
          if (value !== item) {
            value.isActive = false;
          }
        });
        item.isActive = !item.isActive;
      },

      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      sendReport() {
        this.hide();
      },
      backButton() {
        if (this.comingFrom === "mobileChat") {
          this.routeBack("/", false);
        }
        this.hide();
      },

      activateBicoinFAQModal() {
        this.showBicoinFaq = true;
      }
    },

    computed: {
      getModalInfo() {
        let chatID = this.$store.state.activeModal.info.chatID;
        if (chatID) {
          this.chatID = chatID;
        }
        let comingFrom = this.$store.state.activeModal.info.comingFrom;
        if (comingFrom) {
          this.comingFrom = comingFrom;
        }
        let disintermediation = this.$store.state.activeModal.info.disintermediation;
        this.disintermediation = disintermediation;
        let totalBicoin = this.$store.state.activeModal.info.totalBicoin;
        this.totalBicoin = totalBicoin;
        return true;
      },
    }


  }

</script>

<style scoped lang="scss">
  .modal-container {
    margin-top: 60px;
    padding: 0;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    width: calc(100vw - 30px) !important;
    max-width: 450px !important;
    height: calc(100vh - 50px) !important;
    max-height: 435px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    position: relative;
  }
  .disinter-main {
    padding: 20px;
  }

  .disinter-inner {

    .header {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
      margin-bottom: 20px;
    }
    .paragraph1 {
      font-size: 15px;
      line-height: 1.3;
      text-align: left;

    }
    .paragraph2 {
      font-size: 15px;
      line-height: 1.3;
      text-align: left;
    }
    .paragraph-more {
      font-size: 14px;
      line-height: 1.5;
      text-align: left;
      color: #fd4056;
      cursor: pointer;
    }
  }

  .disinter-buttons-div {
    width: 230px;
    margin-top: 30px;
    margin-left: auto;
    display: flex;
    .close-button{
      width: 100px;
      text-align: center;
      cursor: pointer;
      background-color: #FFF;
      border: 2px solid #2d3640;
      border-radius: 6px;
      &:hover {
        color: #FFF;
      }
    }
    .okey-button {
      width: 200px;
      text-align: center;
      border-radius: 6px;
      border: 2px solid #FD4056;
      color: #FD4056;
      cursor: pointer;
      margin-left: 20px;
      &:hover {
        border: 2px solid #e94258;
        color: #e94258;
        background-color: #FFF !important;
      }
    }
  }

  .faq-list-item{
    padding: 10px 0;
  }
  .faq-list-item-question{
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #2d3640;
    padding: 10px 0px;
  }
  .faq-list-item-answer{
    font-size: 16px;
    color: #2d3640;
    padding: 10px 0px;
  }

  .fade-enter-active {
    transition: opacity .5s;
  }
  .fade-enter /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .faq-list{
    padding-bottom: 100px;
  }
</style>
